import React from "react";
import { Slider, Typography} from "@material-ui/core";
import styled from "styled-components";

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
    overflow: hidden;

    background: url("${(props) => props.config.camera}");
    background-repeat: no-repeat;
    background-size: cover;

    header {
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        padding: 20px;
        width: 100%;

        .logo {
            width: 20vw;
            top:20px;
            left: 20px;
            opacity: .8;

            img { width: 100%; }
        }

        .cameraSelect {
            display: flex;
            flex: 1;
            justify-content: flex-end;
            align-items: center;
            margin: 0;
            padding-right: 50px;
            padding: left: 20px;
            

            h4 { margin: 0; } 

            .camera {
                cursor: pointer;
                padding: 20px;
                background-color: rgba(255,255,255, .5);

                &:hover { 
                    background: rgba(0,0,0, .3);
                    color: white; 
                }
            }
        }
    }

    .sliderContainer > * {
        color: #003b5c;
    }

    .sideControls {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        justify-content: space-between;
        margin: 20px 0;
        height: 100%;
        width: 100%;

        .sliderContainer {
            background-color: rgba(255,255,255, .5);
            padding: 5px 10px 50px;

            .label { 
                padding-bottom: 5px; 
                color: black;
                font-weight: 500; 
            }
            font-weight: 500;
            .MuiSlider-rail { opacity: 1;}
            .MuiSlider-markLabel { color: rgba(0,0,0, 1); }
        }

        & > * {
            display: block;
            height: 100%;
            max-height: 600px;
            margin: 25px;
        }
    }

    .bottomControls {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        margin: 25px 0;
        

        .sliderContainer {
            display: block;
            width: 100%;
            max-width: 800px;
            background-color: rgba(255,255,255, .5);
            padding: 5px 20px 0;

            .MuiSlider-rail { opacity: 1;}
            .MuiSlider-markLabel { color: rgba(0,0,0, 1); }

            .label {
                text-align: center;
                color: black;
                font-weight: 500;
            }
        }

        
    }

`;

const camera_1 = "https://cameras.staffordwebsite.com:4443/axis-cgi/mjpg/video.cgi?streamprofile=webpage";
const camera_2 = "https://cameras.staffordwebsite.com:4444/axis-cgi/mjpg/video.cgi?streamprofile=webpage";
const cameras = [camera_1, camera_2];

const panMarks = [
    { value: -180, label: '-180' },
    { value: -135, label: '-135' },
    { value: -90, label: '-90' },
    { value: -45, label: '-45' },
    { value: 0, label: '0' },
    { value: 45, label: '45' },
    { value: 90, label: '90' },
    { value: 135, label: '135' },
    { value: 180, label: '180' },
];

const tiltMarks = [
    { value: -90, label: '-90' },
    { value: -80, label: '-80' },
    { value: -70, label: '-70' },
    { value: -60, label: '-60' },
    { value: -50, label: '-50' },
    { value: -40, label: '-40' },
    { value: -30, label: '-30' },
    { value: -20, label: '-20' },
    { value: -10, label: '-10' },
    { value: 0, label: '0' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
];

const zoomMarks = [
    { value: 0, label: '0' },
    { value: 1, label: '1x' },
    { value: 2, label: '2x' },
    { value: 3, label: '3x' },
    { value: 4, label: '4x' },
    { value: 5, label: '5x' },
    { value: 6, label: '6x' },
    { value: 7, label: '7x' },
    { value: 8, label: '8x' },
    { value: 9, label: '9x' },
    { value: 10, label: '10x' },
    { value: 11, label: '11x' },
    { value: 12, label: '12x' },
];


const Camera = props => {
    const [pan, setPan] = React.useState(0);
    const [tilt, setTilt] = React.useState(0);
    const [to, setTo] = React.useState(null);
    const [tabValue, setTabValue] = React.useState(0);
    const [selectedCameraUrl, setSelectedCameraUrl] = React.useState(camera_1);
    const [showControls, setShowControls] = React.useState(1);

    const onPanChange = (e, value) => {
        setPan(value);

        clearTimeout(to);
        setTo(setTimeout(() => {
            console.log("sent!")
            const url=`https://saffordcamerasapi.azurewebsites.net/api/HttpTrigger1?code=ZnXkj7lYcFre3Swhg2iadD2Lfxqwv8ShtXiwMUhwsXhOaYDrncdrsw==&pan=${value}`;
            fetch(url, { mode: "no-cors" });
        }, 25));
    }
    const onTiltChange = (e, value) => {
        setTilt(value);

        clearTimeout(to);
        setTo(setTimeout(() => {
            const url=`https://saffordcamerasapi.azurewebsites.net/api/HttpTrigger1?code=ZnXkj7lYcFre3Swhg2iadD2Lfxqwv8ShtXiwMUhwsXhOaYDrncdrsw==&tilt=${value}`;
            fetch(url, { mode: "no-cors" });
        }, 25));
    }
    const onZoomChange = (e, value) => {
        setTilt(value);

        var step = 9999/12;
        var apiZoomValue = value * step;

        clearTimeout(to);
        setTo(setTimeout(() => {
            const url=`https://saffordcamerasapi.azurewebsites.net/api/HttpTrigger1?code=ZnXkj7lYcFre3Swhg2iadD2Lfxqwv8ShtXiwMUhwsXhOaYDrncdrsw==&zoom=${apiZoomValue}`;
            fetch(url, { mode: "no-cors" });
        }, 25));
    }

    const onSelectCamera = (event, cameraIndex) => {
        setShowControls(!cameraIndex);
        setSelectedCameraUrl(cameras[cameraIndex]);
    };  


    return (
        <>
        {/* <StyledHeader>
            <img src="../images/stafford_logo.png" />
        </StyledHeader> */}
        
        <StyledDiv config={{ camera: selectedCameraUrl}}>
            <header>
                <div className="logo">
                    <img src="../images/stafford_logo.png" />
                </div>

                <div className="cameraSelect">
                    <h4 className="camera" onClick={(e) => onSelectCamera(e, 0)}>Camera 1</h4>
                    <h4 className="camera" onClick={(e) => onSelectCamera(e, 1)}>Camera 2</h4>
                </div>
            </header>

            

            { showControls ? ( <>
                <div className="sideControls">
                    <div className="sliderContainer">
                        <Typography id="discrete-slider-custom" gutterBottom className="label">
                            Tilt
                        </Typography>
                        <Slider
                            defaultValue={0}
                            orientation="vertical"
                            step={1}
                            valueLabelDisplay="auto"
                            min={-90}
                            max={20}
                            onChange={onTiltChange}
                            className="tiltSlider"
                            marks={tiltMarks}
                        />
                    </div>

                    <div className="sliderContainer">
                        <Typography id="discrete-slider-custom" gutterBottom className="label">
                            Zoom
                        </Typography>
                        <Slider
                            defaultValue={0}
                            orientation="vertical"
                            step={1}
                            valueLabelDisplay="auto"
                            min={0}
                            max={12}
                            onChange={onZoomChange}
                            className="zoomSlider"
                            marks={zoomMarks}
                        />
                    </div>
                </div>

                <div className="bottomControls">
                    <div className="sliderContainer">
                        <Typography id="discrete-slider-custom" gutterBottom className="label">
                            Pan
                        </Typography>
                        <Slider
                            defaultValue={0}
                            step={1}
                            valueLabelDisplay="auto"
                            min={-180}
                            max={180}
                            onChange={onPanChange}
                            className="panSlider"
                            marks={panMarks}
                        />
                    </div>
                </div>
            </> ) : <></> }
        </StyledDiv>
        </>
    );
};

export default Camera;