import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Camera from "./pages/Camera";

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={'/'}
          render={routerProps => <Camera {...routerProps} /> }
        />
      </Switch>
    </Router>
  );
}

export default App;
